// Use .caption to avoid colliding with Bootstrap styling
.caption {
  position: absolute;
  text-align: center;
  margin: 10px;
  pointer-events: none;
  background:rgba(0,0,0,0.9);
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 12px;
  width: auto;
  padding: 4px;
  color:#fff;
  opacity: 1;
  display: none;
}
