.map {
  svg {
    width: 100%;
  }

  .state {
    stroke: #a9a9a9;
    stroke-width: 1;
  }

  .state:hover {
    fill-opacity: 0.6;
    cursor: pointer;
  }
}
