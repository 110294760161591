@import "~bootstrap/scss/bootstrap";

.loading-overlay {
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-color: #000;
}

.loading-overlay .spinner-border {
  width: 5rem;
  height: 5rem;
}
